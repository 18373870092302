





























import { Component, Vue, PropSync } from "vue-property-decorator";
import VersionBackModule from "@/store/modules/VersionBack-module";
@Component({
  components: {}
})
export default class NewVersion extends Vue {
  @PropSync("dialog") dialogSync!: boolean;
  public htmls: string = "";
  public created() {
    VersionBackModule.getVersionFrontData();
  }
  public version() {
    return process.env.VUE_APP_VERSION;
  }
  public get dataVersion() {
    try {
      return VersionBackModule.VersionFrontData.descripcion;
    } catch (error) {
      return "";
    }
  }
}
